<template>
  <layout-private :customer="customer" :pageTitle="'Stats'">
    <div class="page">

      <div class="col-12">
        <select v-if="locations && Object.keys(locations).length" v-model="activeLocationId" class="form-control">
          <option disabled :value="null">Select a Location</option>
          <option v-for="location in locations" :value="location.id">{{ location.name }}</option>
        </select>
      </div> <!-- /.col-x -->

      <!-- team stats: container component -->
      <stats-team-health :activeLocationId="activeLocationId" :activeLocation="activeLocation"></stats-team-health>

      <!-- patient stats: care progress rate -->
      <stats-care-progress class="stat-section" v-if="locations && Object.keys(locations).length" :activeLocationId="activeLocationId" :activeLocation="activeLocation"></stats-care-progress>

      <!-- practice stats: container component -->
      <stats-practice-health class="stat-section" v-if="locations && Object.keys(locations).length" :activeLocationId="activeLocationId" :activeLocation="activeLocation"></stats-practice-health>

    </div>
  </layout-private>
</template>

<script>
import Vue from 'vue'
import LayoutPrivate from '../components/LayoutPrivate.vue'
import {mapGetters} from 'vuex'
import ListErrors from '../components/ListErrors.vue'
import StatsTeamHealth from '../components/StatsTeamHealth.vue'
import StatsCareProgress from '../components/StatsCareProgress.vue'
import StatsPracticeHealth from '../components/StatsPracticeHealth.vue'

export default {
  props: ['customer'],
  components: {
    LayoutPrivate, ListErrors, StatsTeamHealth, StatsCareProgress, StatsPracticeHealth,
  },
  data () {
    return {
      errors: [],
      loading: false,
      patientStatLocation: null,
      practiceStatLocation: null,
      locations: {},
      activeLocationId: null,
    }
  },
  computed: {
    ...mapGetters(['user', 'user_props', 'custom_claims']),
    activeLocation(){
      if(this.activeLocationId && this.locations && Object.keys(this.locations).length) {
        return this.locations[this.activeLocationId];
      }
      return null;
    },
  },
  watch: {
    // check for redirect on loading custom claims
    custom_claims() {
      if(!this.custom_claims) {
        this.$router.replace('/');
        return;
      }
      this.getLocations();
    },
  },
  methods: {
    getLocations() {
      // exit if no sufficient claims
      if(!this.custom_claims || !this.custom_claims.adminLevel || !this.custom_claims.customerId) {
        return;
      }
      // get all locations
      Vue.firestore.collectionGroup('locations')
          .where('customerId', '==', this.custom_claims.customerId)
          // .where('hidden','!=',true) // doesn't work, excludes null values
          .get()
          .then(locsQuerySnap => {
            locsQuerySnap.docs.forEach((loc,i) => {
              // console.log(`loc ${i}`,loc.data());
              // do not add hidden locations
              if(!loc.data().hidden) {
                this.$set(this.locations, loc.id, {
                customerId: this.custom_claims.customerId,
                name: loc.data().locationName,
                id: loc.id,
                groupId: loc.data().groupId,
                hidden: loc.data().hidden || null,
                openDentalStatus: loc.data().open_dental_status || null,
                monthlyRevenueGoal: loc.data().monthly_revenue_goal || null,
                monthlyDays: loc.data().monthly_open_days || null,
              });
              }
            })
          })
          .catch(err => console.log(err));
    },
  },
  mounted () {
    this.getLocations();
  },
}
</script>

<style lang="less" scoped>
.page {
  padding-top: 30px;
}
.stat-section {
  margin-bottom: 30px;
}
</style>

<template lang="html">
  <div class="activeChannel">

    <p v-if="loading" class="p-top text-center"><i class="far fa-fw fa-pulse fa-spinner"></i></p>

    <p v-if="!loading && !Object.keys(messages).length" class="p-top light-weight sm text-center"><em>There aren't any messages yet in this channel</em></p>

    <div v-if="messages && Object.keys(messages).length">
      <div v-if="messagesToday && Object.keys(messagesToday).length" class="messagesToday">
        <div class="inner">
          <h1>Today</h1>
          <message-preview v-for="(message,id) in messagesToday" :key="id" :message="{...message, id: id}" class="message" :class="message.post_type"></message-preview>
        </div>
      </div> <!-- /.today -->
      <div v-if="messagesYesterday && Object.keys(messagesYesterday).length" class="messages messagesYesterday">
        <h1>Yesterday</h1>
        <message-preview v-for="(message, id) in messagesYesterday" :key="id" :message="{...message, id: id}" class="message" :class="message.post_type"></message-preview>
      </div> <!-- /.yesterday -->
      <div v-if="messagesOlder && Object.keys(messagesOlder).length">
        <div v-for="dateGroup in messagesOlder" class="messages messagesOlder">
          <h1>{{ dateGroup.key | formatDate }}</h1>
          <message-preview v-for="message in dateGroup.values" :key="message.id" :message="message" class="message" :class="message.post_type"></message-preview>
        </div>
      </div> <!-- /.messagesOlder -->
    </div>
    <div class="channel-footer text-center">
      <hr class="hr-light" />
      <p class="light-weight sm"><em>showing messages for past {{ messagesRangeEnd  }} days</em></p>
      <p><button v-if="messagesRangeEnd < 90" @click="loadOlderMessages" class="btn btn-icon-left btn-dim"><i class="far fa-calendar-plus"></i>Load Past {{ messagesRangeEnd + 30 }} Days</button></p>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import {mapGetters} from 'vuex'
import MessagePreview from '../components/MessagePreview.vue'

export default {
  props: ['channel'],
  components: {
    MessagePreview
  },
  data () {
    return {
      loading: true,
      messages: {},
      messagesRangeStart: 0,
      messagesRangeEnd: 30,
    }
  },
  computed: {
    ...mapGetters(['user', 'user_props', 'custom_claims']),
    messagesRangeStartDate() {
      // let thirtyDaysAgoTimestamp = new Date();
      // thirtyDaysAgoTimestamp.setDate(thirtyDaysAgoTimestamp.getDate() - 30);
      // thirtyDaysAgoTimestamp = thirtyDaysAgoTimestamp / 1000;
      // console.log({thirtyDaysAgoTimestamp})
      let startTimestamp = new Date();
      startTimestamp.setDate(startTimestamp.getDate() - this.messagesRangeStart);
      return startTimestamp / 1000;
    },
    messagesRangeEndDate() {
      // let thirtyDaysAgoTimestamp = new Date();
      // thirtyDaysAgoTimestamp.setDate(thirtyDaysAgoTimestamp.getDate() - 30);
      // thirtyDaysAgoTimestamp = thirtyDaysAgoTimestamp / 1000;
      // console.log({thirtyDaysAgoTimestamp})
      let endTimestamp = new Date();
      endTimestamp.setDate(endTimestamp.getDate() - this.messagesRangeEnd);
      return endTimestamp / 1000;
    },
    messagesToday() {
      if(this.messages && Object.keys(this.messages).length) {
        const today = new Date().toISOString().slice(0, 10).replaceAll('-',''); // like 20220914, for easy filtering
        const entries = Object.entries(this.messages);
        const filtered = entries.filter(([id,m]) => m.date == today);
        // sort descending
        filtered.sort((prev, next)=> next.key - prev.key);
        return Object.fromEntries(filtered);
      }
      return null;
    },
    messagesYesterday() {
      if(this.messages && Object.keys(this.messages).length) {
        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(yesterday.getDate() - 1)
        const yesterdayStamp = yesterday.toISOString().slice(0, 10).replaceAll('-',''); // like 20220914, for easy filtering
        // console.log({yesterdayStamp})
        const entries = Object.entries(this.messages);
        const filtered = entries.filter(([id,m]) => m.date == yesterdayStamp);
        // sort descending
        filtered.sort((prev, next)=> next.key - prev.key);
        return Object.fromEntries(filtered);
      }
      return null;
    },
    messagesOlder() {
      if(this.messages && Object.keys(this.messages).length) {
        // filter out older posts
        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(yesterday.getDate() - 1)
        const yesterdayStamp = yesterday.toISOString().slice(0, 10).replaceAll('-',''); // like 20220914, for easy filtering
        // const entries = Object.entries(this.messages);
        // const filtered = entries.filter(([id,m]) => m.date < yesterdayStamp);

        // group by date
        // const values = Object.values(this.messages);
        const entries = Object.entries(this.messages);
        // build new array of message objects that includ message id
          // id must be included inside each message so they can be marked as read in Message.vue
        const values = entries.reduce(
          (combined,next) => {
            // console.log('next',next)
            // create new object including id and expanded original object
            combined.push({id: next[0], ...next[1]});
            // console.log('combined',combined)
            return combined;
          },
          // set initial value different from [0], to array containing first correct object
          // [{id: entries[0][0], ...entries[0][1]}]
          // set initial value different from [0], empty array
          []
        );
        // console.log('values',values)
        const filtered = values.filter((m) => m.date < yesterdayStamp);
        const grouped = this.groupBy(filtered,'date');
        // sort descending
        grouped.sort((prev, next)=> next.key - prev.key);
        return grouped;

        // old return
        // return Object.fromEntries(filtered);

      }
      return null;
    },
  },
  watch: {
    channel(newChannel, oldChannel) {
      // when new channel set, reset data
      if(newChannel) {
        // console.log({newChannel})
        if(Object.keys(this.messages).length) {
          this.messages = {};
        }
        this.loadMessages();
      }
    },
  },
  methods: {
    // increase messagesRangeEnd
    loadOlderMessages() {
      // only increase if less than hard End of past 90 days
      if(this.messagesRangeEnd < 90) {
        this.messagesRangeEnd += 30;
        this.messagesRangeStart += 30;
        this.loadMessages();
      }
    },
    // group values of an array of objects by key into array of objects with nested array of matches
      // via https://stackoverflow.com/questions/14446511/most-efficient-method-to-groupby-on-an-array-of-objects#comment64856953_34890276
      // duplicated from RewardsLeaderboardView.vue
    groupBy(xs, key) {
      return xs.reduce(function (rv, x) {
        let v = key instanceof Function ? key(x) : x[key];
        let el = rv.find((r) => r && r.key === v);
        if (el) { el.values.push(x); }
        else { rv.push({ key: v, values: [x] }); }
        return rv;
      }, []);
    },
    loadMessages() {
      // console.log('loadMessages')
      if(!this.channel || !this.custom_claims || !this.custom_claims.customerId || !this.messagesRangeStartDate || !this.messagesRangeEndDate) {
        return;
      }

      let messagesQuery;
      let groupMessagesQuery;
      let messagesProms = [];
      // load messages using combination of filters based on channel.type
      if(this.channel.type == "allLocations" && this.channel.groups) {
        // match messages by customerId && any groupId
          // (which includes location channel types since they also have groupId)
        messagesQuery = Vue.firestore.collectionGroup('messages')
          .where('customerId', '==', this.custom_claims.customerId)
          // .where('groupId', '!=', false)
          .where('groupId', 'in', this.channel.groups)
          .where('timestamp', '>=', this.messagesRangeEndDate)
          .where('timestamp', '<=', this.messagesRangeStartDate)
          .orderBy('timestamp', 'desc');
      } else if(this.channel.type == "group") {
        // match messages with customerId and groupId
          // will also match location messaged since they also have groupId
        messagesQuery = Vue.firestore.collectionGroup('messages')
          .where('customerId', '==', this.custom_claims.customerId)
          .where('groupId', '==', this.channel.id)
          .where('timestamp', '>=', this.messagesRangeEndDate)
          .where('timestamp', '<=', this.messagesRangeStartDate)
          .orderBy('timestamp', 'desc');
      } else if(this.channel.type == "location") {
        // match messages with customerId && locationId
        messagesQuery = Vue.firestore.collectionGroup('messages')
          .where('customerId', '==', this.custom_claims.customerId)
          .where('locationId', '==', this.channel.id)
          .where('timestamp', '>=', this.messagesRangeEndDate)
          .where('timestamp', '<=', this.messagesRangeStartDate)
          .orderBy('timestamp', 'desc');
        // also load messages to the group this location belongs to
        groupMessagesQuery = Vue.firestore.collectionGroup('messages')
          .where('customerId', '==', this.custom_claims.customerId)
          .where('groupId', '==', this.channel.groupId)
          .where('channel_type', '==', 'group')
          .where('timestamp', '>=', this.messagesRangeEndDate)
          .where('timestamp', '<=', this.messagesRangeStartDate)
          .orderBy('timestamp', 'desc');
      } else if(this.channel.type == "role") {
        // match messages with roleId && customerId
        messagesQuery = Vue.firestore.collectionGroup('messages')
          .where('customerId', '==', this.custom_claims.customerId)
          .where('roleId', '==', this.channel.id)
          .where('timestamp', '>=', this.messagesRangeEndDate)
          .where('timestamp', '<=', this.messagesRangeStartDate)
          .orderBy('timestamp', 'desc');
      }

      // if not a role channel, load allLocations messages
      if(this.channel.type !== "role") {
        let loadMessagesAllLocationsProm = Vue.firestore.collectionGroup('messages')
          .where('customerId', '==', this.custom_claims.customerId)
          .where('channel_type', '==', 'allLocations')
          .where('timestamp', '>=', this.messagesRangeEndDate)
          .where('timestamp', '<=', this.messagesRangeStartDate)
          .orderBy('timestamp', 'desc')
          .onSnapshot(
            messagesAllLocationsQS => {
              if(!messagesAllLocationsQS.empty){
                // console.log(messagesAllLocationsQS.docs);
                messagesAllLocationsQS.docs.forEach(docSnap => {
                  // this.messages.push(docSnap.data());
                  this.$set(this.messages, docSnap.id, docSnap.data());
                })
              }
            },
          err => {console.log(err)}
        );
          // .catch(err => console.log(err));
        messagesProms.push(loadMessagesAllLocationsProm);
      }
      if(messagesQuery) {
        let loadMessagesProm = messagesQuery
          .onSnapshot(
            messagesQS => {
              if(!messagesQS.empty){
                // console.log(messagesQS.docs);
                messagesQS.docs.forEach(docSnap => {
                  // this.messages.push(docSnap.data());
                  this.$set(this.messages, docSnap.id, docSnap.data());
                })
              }
            },
            err => {console.log(err)}
          );
        messagesProms.push(loadMessagesProm);
      }
      if(groupMessagesQuery) {
        let loadGroupMessagesProm = groupMessagesQuery
          .onSnapshot(
            groupMessagesQS => {
              if(!groupMessagesQS.empty){
                // console.log(messagesQS.docs);
                groupMessagesQS.docs.forEach(docSnap => {
                  // this.messages.push(docSnap.data());
                  this.$set(this.messages, docSnap.id, docSnap.data());
                })
              }
            },
            err => {console.log(err)}
        );
          // .catch(err => console.log(err));
        messagesProms.push(groupMessagesQuery);
      }
      Promise.all(messagesProms)
        .then(() => {
          // console.log('done loading messages')
          this.loading = false;
        })
    },
  },
  mounted() {
    this.loadMessages();
  },
}
</script>

<style lang="less" scoped>
@import "../assets/less/variables.less";

.activeChannel {
  height: 100%;
  overflow-y: scroll;
}
.p-top {
  margin-top: 30px;
}
h1 {
  margin: 0 0 25px;
}
.messages {
  padding: 30px 40px 0;
  margin-bottom: 40px;
}
.messagesToday {
  padding-bottom: 38px;
  margin-bottom: 40px;
  background: url('../assets/img/bg-arrow-down.svg') center bottom no-repeat;
  background-size: 100% 40px;

  .inner {
    padding: 30px 40px;
    background-color: @color-yellow;
  }
}
.channel-footer {
  margin-bottom: 17px;
  .hr-light {
    margin: 20px 0;
  }
  p {
    margin-bottom: 10px;
  }
}

</style>

<template lang="html">
  <div>
    <p class="subtitle"><strong>Team Health</strong></p>
    <h3>Who's caught up in Smiley?</h3>
    <p>Percentage of team members at this primary location who viewed all their updates in Smiley.</p>

    <p v-if="!activeLocationId" class="light-weight"><em>Please select a location above.</em></p>

    <div v-if="activeLocationId && userCount && userCount>0" class="chart chart-bars chart-caught-up">
      <div class="bars-container d-flex flex-row">
        <div
          class="bar-container yesterday d-flex flex-column justify-content-end"
          @click="toggleActiveBar(1)"
          :class="{active: activeBar==1}"
        >
          <p class="label" :style="{bottom: yesterday + '%'}">{{ yesterday }}%</p>
          <div class="bar" :style="{height: yesterday + '%'}"></div>
        </div>
        <div
          class="bar-container one-week d-flex flex-column justify-content-end"
          @click="toggleActiveBar(2)"
          :class="{active: activeBar==2}"
        >
          <p class="label" :style="{bottom: oneWeek + '%'}">{{ oneWeek }}%</p>
          <div class="bar" :style="{height: oneWeek + '%'}"></div>
        </div>
        <div
          class="bar-container two-weeks d-flex flex-column justify-content-end"
          @click="toggleActiveBar(3)"
          :class="{active: activeBar==3}"
        >
          <p class="label" :style="{bottom: twoWeeks + '%'}">{{ twoWeeks }}%</p>
          <div class="bar" :style="{height: twoWeeks + '%'}"></div>
        </div>
        <div
         class="bar-container one-month d-flex flex-column justify-content-end"
         @click="toggleActiveBar(4)"
          :class="{active: activeBar==4}"
        >
          <p class="label" :style="{bottom: oneMonth + '%'}">{{ oneMonth }}%</p>
          <div class="bar" :style="{height: oneMonth + '%'}"></div>
        </div>
      </div> <!-- /.bars-container -->
      
      <div class="labels-x d-flex flex-row">
        <p>yesterday</p>
        <p>1 wk</p>
        <p>2 wks</p>
        <p>1 mo</p>
      </div> <!-- /.labels-x -->
    </div> <!-- /.chart -->

  </div> <!-- /.col -->
</template>

<script>
import Vue from 'vue'
import {mapGetters} from 'vuex'

export default {
  props: ['activeLocationId'],
  data () {
    return {
      yesterday: 0,
      oneWeek: 0,
      twoWeeks: 0,
      oneMonth: 0,
      userCount: 0,
      activeBar: null,
      // testing
      // yesterday: 40,
      // oneWeek: 70,
      // twoWeeks: 80,
      // oneMonth: 100,
    }
  },
  computed: {
    ...mapGetters(['user', 'user_props', 'custom_claims']),
  },
  watch: {
    custom_claims() {
      if(this.custom_claims) {
        this.loadTeamStats();
      }
    },
    activeLocationId() {
      if(this.activeLocationId) {
        this.loadTeamStats();
      }
    },
  },
  methods: {
    toggleActiveBar(barIndex) {
      if(this.activeBar == barIndex) {
        this.activeBar = null;
      } else {
        this.activeBar = barIndex;
      }
    },
    loadTeamStats() {
      if(!this.custom_claims || !this.custom_claims.customerId || !this.activeLocationId) {
        this.yesterday = 0;
        this.oneWeek = 0;
        this.twoWeeks = 0;
        this.oneMonth = 0;
        this.userCount = 0;
        return;
      }
      // console.log('loading team stats')
      Vue.firestore.collection(`users/`)
          .where('customerId', '==', this.custom_claims.customerId)
          .where('status', '==', 'accepted')
          .where('primary_location', '==', this.activeLocationId)
          .get()
          .then(usersSnap => {
            if(usersSnap.empty) {
              console.log('no users found in StatsCaughtUp.vue')
              this.yesterday = 0;
              this.oneWeek = 0;
              this.twoWeeks = 0;
              this.oneMonth = 0;
              this.userCount = 0;
              return;
            }
            this.userCount = usersSnap.docs.length;
            // console.log('userCount', this.userCount)

            // get date offsets
            const yesterday = new Date();
            yesterday.setDate(yesterday.getDate() - 1)
            const yestDate = yesterday.toISOString().slice(0, 10).replaceAll('-','');

            const oneWkAgo = new Date();
            oneWkAgo.setDate(oneWkAgo.getDate() - 7)
            const oneWkDate = oneWkAgo.toISOString().slice(0, 10).replaceAll('-','');

            const twoWkAgo = new Date();
            twoWkAgo.setDate(twoWkAgo.getDate() - 14)
            const twoWkDate = twoWkAgo.toISOString().slice(0, 10).replaceAll('-','');

            const oneMoAgo = new Date();
            oneMoAgo.setDate(oneMoAgo.getDate() - 28)
            const oneMoDate = oneMoAgo.toISOString().slice(0, 10).replaceAll('-','');

            // set yesterday
            let yestCount = usersSnap.docs.filter((u) => u.data().caught_up_as_of >= yestDate).length;
            // console.log('yestCount',yestCount);
            this.yesterday =  Math.round(yestCount / this.userCount * 100);
            // set oneWeek
            let oneWkCount = usersSnap.docs.filter((u) => u.data().caught_up_as_of >= oneWkDate).length;
            // console.log('oneWkCount',oneWkCount);
            this.oneWeek =  Math.round(oneWkCount / this.userCount * 100);
            // set twoWeeks
            let twoWkCount = usersSnap.docs.filter((u) => u.data().caught_up_as_of >= twoWkDate).length;
            // console.log('twoWkCount',twoWkCount);
            this.twoWeeks =  Math.round(twoWkCount / this.userCount * 100);
            // set oneMonth
            let oneMoCount = usersSnap.docs.filter((u) => u.data().caught_up_as_of >= oneMoDate).length;
            // console.log('oneMoCount',oneMoCount);
            this.oneMonth =  Math.round(oneMoCount / this.userCount * 100);
          })
          .catch(err => console.log(err));
    },
  },
  mounted () {
    this.loadTeamStats();
  },
}
</script>

<style lang="less" scoped>
@import "../assets/less/variables.less";

.hr-chart {
  border-top: 3px solid @dark-color;
  margin: 50px 0 30px;
}
.subtitle {
  margin: 0 0 5px;
}
h3 {
  margin: 0 0 10px;
}
.chart-caught-up {
  padding-top: 15px;

  .bar-container,
  .labels-x p {
    width: 25%;
    text-align: center;
  }
  .bar-container {
    height: 150px;
  }
  .bar {
    width: 65px;
    margin: 0 auto; // center horizontally
  }
  .yesterday .bar {
    background-color: @color-green;
  }
  .one-week .bar {
    background-color: @color-yellow;
  }
  .two-weeks .bar {
    background-color: @color-orange;
  }
  .one-month .bar {
    background-color: @color-salmon;
  }
  .bar-container.active {
    cursor: pointer;

    .label {
      visibility: visible;
      opacity: 1;
    }
    .bar {
      background-color: @dark-color !important;
    }
  }
}

</style>
